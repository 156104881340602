* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0;
}
body {
  background: url("/src/assets/DALL·E 2024-02-17 21.00.46 - An anime-style landscape scene, richly infused with elements of Egyptian mythology, horizontally oriented for a panoramic view. At the far left, a cha.webp");
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

.HeaderNav {
  background: url("./assets/navheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: normal;
  background-position: center center;
}
.active {
  border-bottom: white;
  border-bottom: 3px solid;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  filter: drop-shadow(0px 5px 5px #9edaff);
}
.Contact {
  background: url("./assets/contact.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: normal;
  background-position: center bottom;
}
.lightBlue-color {
  background: #f4f8fc;
}
.blue-color5 {
  background: #88a9cc;
}
.blue-color {
  background: #195390;
}
.blue-color2 {
  background: #284059;
}
.blue-color2Opicity {
  background: #27477279;
}
.blue-color3Opicity {
  background: #ffffff8f;
}
.blue-color3 {
  background: #0f1a2e;
}
.text-blue {
  color: #195390;
}

.neon {
  -webkit-filter: drop-shadow(9px 5px 17px #195390);
  filter: drop-shadow(9px 5px 17px #195390);
}
a.nav-link {
  color: white !important;
}

/*//------------ Right box ------------*/
.right-box {
  padding: 40px 30px 40px 40px;
}
/*//------------ Custom Placeholder ------------*/
::placeholder {
  font-size: 16px;
}
.rounded-4 {
  border-radius: 20px;
}
.rounded-5 {
  border-radius: 30px;
}
/*//------------ For small screens------------*/
@media only screen and (max-width: 768px) {
  .box-area {
    margin: 0 10px;
  }
  .left-box {
    height: fit-content;
    overflow: hidden;
  }
  .right-box {
    padding: 20px;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.duration-75 {
  transition-duration: 75ms;
}

/* Cart  */

.card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.card-registration .select-arrow {
  top: 13px;
}

.bg-grey {
  background-color: #eae8e8;
}
.nav-link {
  color: white !important;
}

@media (min-width: 992px) {
  .card-registration-2 .bg-grey {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

@media screen and (min-width: 992px) {
  .Contact {
    background: url("./assets/contact.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: normal;
    background-position: center 80%;
  }
}

@media (max-width: 991px) {
  .card-registration-2 .bg-grey {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .activeLink .active {
    border-bottom: white;
    border-bottom: 3px solid;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    filter: drop-shadow(0px 5px 5px #9edaff);
  }
}
button.active {
  background: #284059;
}
.z--10 {
  z-index: -10;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}

.dropdown-item:hover {
  color: white;
  background-color: #284059;
}
.dropdown-item:focus {
  color: white;
  background-color: #195390;
}
.dropdown-item {
  overflow: hidden;
}

/* @ Transtion */
.bg-darkerBlue {
  color: #005b96;
}
.bg-darkBlue {
  color: #005b96;
}
.bg-lightBlue {
  color: #005b96;
}
.CartBadge {
  top: -4px;
  left: 24px;
}
.cardGame {
  transform: 3s;
}
.cardGame :hover {
  filter: drop-shadow(0px 0px 2px #005b96);
}

div:where(.swal2-container) .swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  overflow-x: hidden !important;
}
.pointer {
  cursor: pointer;
}

.blockquote-custom-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  left: 19px;
}

nav li {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.lineHeight {
  line-height: 2.5rem;
}
/* Hide the radio buttons */
.radio-input {
  display: none;
}

/* Style the divs as buttons */
.radio-label {
  display: inline-block;
}

.radio-label .btn {
  cursor: pointer;
  width: 100%;
}
.cursor {
  cursor: pointer;
}
.content {
  width: fit-content;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
}

.radio-label .btn:focus {
  outline: none;
}

.radio-label .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.radio-label .btn-outline-success.active {
  background-color: #28a745;
  color: #fff;
}
.testimonial-card .avatar {
  width: 120px;
  margin-top: -60px;
  overflow: hidden;
  border: 5px solid #fff;
  border-radius: 50%;
}
.testimonial-card .card-up {
  height: 120px;
  overflow: hidden;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.aqua-gradient {
  background: linear-gradient(40deg, #0b3e6b, #1bbdc9) !important;
}
.font {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.fontt {
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
}
.font-footer {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
